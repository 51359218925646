html, body, #root{
	--heightHead: 50px;
	--txtColorHead: black;
	--bkgColorHead: linear-gradient(10deg, silver, gray 25%, silver);
	--bkgColorBody: radial-gradient(circle farthest-corner at 50% 120%,#d8dae7,#7a8bdc 36%,#5788ab 56%,#7470a7 89%);

	position: relative;
	width: 100%;
	min-height: 100%;
	color: black;
}

body {
	background-image: var(--bkgColorBody);
}

.galeryImage{
	width: 15rem;
    margin: 2rem;
}

.centralText {
	text-align: center;
}

.head, .roadmap {
	height: 40rem;
	background-image: radial-gradient(circle farthest-corner at 50% 120%,#bfc5ff,#7a8bdc 36%,#366586 56%,#262256 89%);
	border-radius: 2rem;
	box-shadow: 15px 15px 15px #17161e;
}

.roadmap {
	top: 40rem;
    margin-top: 2rem;
}

.rect1 {
	width: 20rem;
	height: 20rem;
	position: absolute;
	background: #000;
	left: 2rem;
    top: 10rem;
}

.tenisplace {
	position: absolute;
	height: 40rem;
	width: 100%;
	border-radius: 2rem;
	/*background-image: url("./images/tenisplace.png");*/
	background-size: cover;
	opacity: 0.05;
}

.rect2 {
	width: 20rem;
	height: 20rem;
	position: absolute;
	background: #000;
	right: 2rem;
    top: 10rem;
}

a{
	color: var(--txtColor);
}

.App {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	text-align: center;
}

header{
	position: fixed;
	top: 0;
	left: 0;
	display: block;
	height: var(--heightHead);
	width: 100%;
	flex: 0;
	border-radius: 1rem;
	background: azure;
}

.content{
	flex: 1;
	margin-top: var(--heightHead);
	padding: 1em;
	/* overflow-x: hidden;
	overflow-y: visible; */
}

button,
a.button{
	color: var(--bkgColorHead);
	box-shadow: inset 1px 3px 25px 0px rgb(10 18 45 / 50%);
	font-size: inherit;
	font-weight: bold;
	padding: 0.5em 2em;
	border: solid 1px var(--txtColorHead);
	border-radius: 5px;
	cursor: pointer;
	text-decoration: none;
}

button:hover, a.button:hover {
	background: antiquewhite;
}

/*
		HEADER
*/
header{
	color: var(--txtColorHead);
	box-shadow: inset 1px 3px 25px 0px rgba(10, 18, 45, 0.5), 1px 1px 25px 0px #000;
	z-index: 100;
}

header #logo{
	margin: 0;
	float:left;
	font-size: 2em;
	margin: 10px 1em;
	height: 30px;
	color: var(--txtColorHead);
}

header a{
	color: var(--txtColorHead);
	text-decoration: none;
	font-weight: bold;
}

header nav{
	margin: 0;
	float:right;
}

header nav ul{
	list-style: none;
	float:right;
}

header nav ul li{
	float:left;
	margin-right: 20px;
	font-size: 1em;
}

/*
	LOGIN
*/
button.login{
	font-size: 14px;
  	margin-top: -16px;
}